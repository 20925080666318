import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import img1 from "../../logos/photos/picture12.jpg"

import img2 from "../../logos/photos/unnamed2.jpg"

import { useNavigate } from 'react-router-dom';

export default function Haptic() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-12">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                Inflated Haptic Membrane Sensors: Bringing Back the Surgeon’s Touch
             </h3>
             <p>Tissue palpation is a critical diagnostic technique used by clinicians to identify abnormality within tissue. Doctors can evaluate various aspects of tissue health, such as the presence of lumps, swellings, or textural changes simply by touching and feeling for their presence. This hands-on approach provides invaluable information, but is not always an option, as, for example, with Minimally Invasive Surgery (MIS). MIS offers many benefits, including reduced recovery times and less postoperative pain, but comes with the significant drawback of limiting surgeons' direct access to body tissue and organs. The absence of tactile feedback, coupled with restricted visibility, makes it challenging for surgeons to accurately assess tissue characteristics.
             </p>
             <h5 style={{color:'#474545'}}>The Challenge in Minimally Invasive Surgery</h5>
             <p>In traditional surgery, surgeons rely heavily on their sense of touch to feel and assess tissue health, using their fingers and hands to detect anything unusual. In MIS, the opportunity for this kind of tactile feedback is unavailable and surgeons must therefore rely almost exclusively on what is visible on a screen. While visual cues are important, they do not fully compensate for the absence of tactile cues, and the detection of abnormalities such as tumours therefore becomes more challenging. </p>
             <p>To overcome this key limitation, advanced technologies are now being integrated into surgical tools, fundamentally changing the way in which surgeons analyse tissue characteristics. Before examining how these advancements are incorporated into MIS probes, we need to first consider how tissue stiffness can be measured.</p>
             <h5 style={{color:'#474545'}}>How Force Sensors Measure Tissue Stiffness</h5>
             <p>To understand how these sensors work, imagine squeezing a sponge. The extent to which it compresses gives you a good indication as to how soft or how firm it is. Force sensors in medical tools work in a similar way, albeit to a far greater degree of precision.</p>
             <p>Here's a simple breakdown:</p>
             <p><b>Applying Force:</b> The sensor-equipped tool presses upon the tissue, while measuring exactly how much force is being applied.</p>
             <p><b>Measuring Deformation:</b> The sensor also quantifies the extent of the compression under that force. The softer the tissue, the greater the compression. </p>
             <p><b>Calculating Stiffness:</b> By comparing the force applied and the consequent tissue deformation, the sensor can calculate the tissue’s stiffness, using Hooke’s law 
             <p id='center'><b style={{fontWeight:'600'}}>F=k*x</b></p>
             <p>where F is the applied force, x is the deformation, and k is the stiffness of the material.</p>
             <p>The resulting stiffness value offers doctors vital diagnostic information that would otherwise have been unavailable to them. </p>
</p>
             <h5 style={{color:'#474545'}}>Why an Inflated Membrane Instead of Other Methods?</h5>
             <p>Within the broad approach outlined above, several methods can be employed to estimate tissue stiffness, and so to assess the inflated membrane haptic sensor, we need to consider the strengths and weaknesses of these comparable alternatives: </p>
             <p><b style={{fontWeight:'600'}}>Multiple Springs Method:</b> In this approach, springs of varying stiffness levels are used to measure tissue stiffness. Each spring reacts differently depending on the firmness of the tissue against which it is pressed - the extent of the spring’s compression enabling us to estimate the tissue stiffness. However, this method is limited by the fact that it works on predefined stiffness levels. This means that the system can only measure within the narrow ranges set by the springs, potentially missing subtle differences in tissue stiffness that might be crucial in a medical setting. Additionally, the need for multiple springs and related components makes the system more complex, less adaptable and rather challenging to miniaturise, which can be a significant drawback when using within the constrained environment of MIS.</p>
             <p><b style={{fontWeight:'600'}}>Indentation Technique:</b> With this technique, a probe—typically a small ball bearing—is pressed into the tissue and the resulting indentation measured. While this method can provide accurate stiffness readings at the point of contact it also comes with its own set of challenges. Indentation is generally more invasive, which is not ideal, especially in delicate or hard-to-reach areas of the body. Moreover, the tools used in indentation techniques tend to be bulky and potentially too large to fit into the slender, flexible instruments needed for MIS, ultimately limiting their practicality.</p>
             <p><b style={{fontWeight:'600'}}>Ultrasound Elastography:</b> This is a non-invasive imaging technique that uses sound waves to create a visual map of tissue stiffness. Although a powerful imaging tool that offers a broad view of the tissue, it does have certain limitations when used in real-time surgical contexts. Elastography equipment is expensive and requires specialized training to operate and interpret, making it more suitable for diagnostic imaging rather than for real-time feedback during surgery. This is compounded by the fact that the process is not always quick enough for the fast-paced environment of surgery, where real-time data is crucial.</p>
             <p><b style={{fontWeight:'600'}}>Tactile Imaging Sensors:</b> Tactile Imaging Sensors aim to mimic the human sense of touch by mapping the pressure distribution across a tissue surface. These sensors can offer detailed information about tissue properties, but also have drawbacks. Implementing these sensors effectively can be challenging, especially given the limited space available during MIS. While they provide detailed pressure maps, they may not be flexible enough to adapt to the varied and dynamic environments encountered during surgery.</p>
             <h5 style={{color:'#474545'}}>Advantages of the Inflated Membrane Haptic Sensor:</h5>
             <p>The Inflated Membrane Haptic Sensor addresses many of the challenges associated with these more traditional methods, particularly in relation to MIS. Unlike methods that rely on fixed components like springs, the stiffness of the inflated membrane can be adjusted on the fly by altering the internal air pressure. This adjustability allows the sensor to respond dynamically to different tissue types in real-time, which is crucial during surgery. Instead of providing discrete stiffness levels, the inflated membrane offers a continuous spectrum, smoothly transitioning between levels of stiffness. This results in a more accurate and nuanced reading of tissue properties.</p>
             <p>Additionally, the membrane can conform to the shape of the tissue, just as a balloon wraps around an object. The simplicity of the inflated membrane design also makes it more compact and easier to integrate into existing surgical tools such as endoscopes, without adding extra bulk. The two notable approaches using inflatable membranes for stiffness estimation are:</p>
             <p><b style={{fontWeight:'600'}}>Abraded Optical Fiber-Based: </b> These sensors use an abraded optical fibre to measure forces. Their limitations include poor mechanical strength of the fibre due to abrasion and increased likelihood of fracture, rendering them less than ideal for practical use.</p>
             <p><b style={{fontWeight:'600'}}>Light Reflection-Based: </b> These sensors use light reflection to measure force, whereby changes in light intensity correspond to changes in force. Their key feature is an airtight silicone dome that adjusts its stiffness using air pressure, enabling flexible and accurate force measurement.
The cross sections of these sensors are shown in the figure below:
</p> <figure> <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <figcaption style={{width:'620px', fontSize:'13px'}}>Figure 1: a) shows the cross-section of a light reflection-based sensor and b) shows the cross-section of an abraded optical fibre-based sensor.</figcaption>
                </figure>
                <br/>
             <p><b style={{fontWeight:'600'}}>Conclusion: </b></p>
             <p>The development of the Inflated Membrane Haptic Sensor represents a major step forward in medical technology, particularly within the context of Minimally Invasive Surgery (MIS). This innovative device addresses many of the challenges posed by traditional methods of stiffness estimation by providing a flexible and adaptive solution that delivers real-time feedback during surgery. By restoring the crucial sense of touch that is effectively lost in MIS, the inflated membrane sensor has the potential to significantly enhance surgical precision and improve patient outcomes. Looking ahead, future advancements in this technology are likely to focus on further refining the sensor's accuracy, miniaturizing its design for seamless integration into existing surgical tools, and incorporating advanced materials to enhance its functionality in dynamic surgical environments.</p>
             <p><a href='https://www.linkedin.com/in/abubakardawood/'>Dr Abu Bakar Dawood,</a>  September 2024</p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
              
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
