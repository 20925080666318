import React from "react";

import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/team/PageHeader";


import img1 from "../../logos/photos/unnamed.jpg"

import img2 from "../../logos/photos/unnamed2.jpg"

import { useNavigate } from 'react-router-dom';

export default function TAROS() {
  const navigate = useNavigate();
  function handleClick1() {

    navigate('/PALPABLE_project_kicks_off_with_a_bang');
  }
  function handleClick2() {

    navigate('/');
  }
  return (
    <Layout>
      <Navbar  />
      <PageHeader />
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3 style={{color:'#095a4f'}}>
                Latest PALPABLE research presented at TAROS 2024
             </h3>
             <p>PALPABLE was proudly featured at this year’s <a href='https://taros-conference.org/'>Towards Autonomous Robotic Systems conference (TAROS)</a>, hosted by 
             <a href='https://www.brunel.ac.uk/research/Centres/Brunel-Digital-Science-and-Technology-Hub/Centre-for-Autonomous-Systems-and-Robotics'> Brunel University London</a> from 21-23 August 2024. TAROS is the longest running UK-hosted international conference on robotics and autonomous systems (RAS).</p>
              <p>An extended abstract entitled “Variable Stiffness & Dynamic Force Sensor for Tissue Palpation”, was presented at the conference by lead author <a href="https://www.linkedin.com/in/abubakardawood/">Abu Bakar Dawood</a> from 
              partner <a href='https://www.seresearch.qmul.ac.uk/robotics/'>Queen Mary University of London</a>. Researchers from PALPABLE partners <a href='https://www.hhi.fraunhofer.de/en/index.html'>FRHHI</a>, Zhenyu Zhang 
              and <a href='https://www.linkedin.com/in/martin-angelmahr-032564b2/'>Martin Angelmahr</a>, <a href='https://www.unito.it/'> UNITO</a>, <a href='https://www.linkedin.com/in/alberto-arezzo-06208742'>Alberto Arezzo</a>, and QMUL, <a href='https://www.linkedin.com/in/kaspar-althoefer-92368b'>Kaspar Althoefer</a> co-authored the paper.</p>
              <p>The research showcases the state of the art of PALPABLE’sinnovative sensor for tissue palpation for use during Minimally Invasive Surgery (MIS). Features include adjustable stiffness using pneumatic pressure allowing for a dynamic force range, optical sensing to estimate deformation and applied force and the soft silicon dome, suitable for interactions with soft tissue. </p>
              <p>Findings show that even at its lowest sensitivity setting, the sensor can detect forces nearly as subtle as human touch. This represents a significant step forward in bringing the sense of touch back to minimally invasive procedures, potentially improving surgical outcomes and patient care.</p>
              <p>The research also highlights the next stages of development: to employ data-driven methods to estimate interaction forces and tissue stiffness and to miniaturize the sensor to a maximum diameter of 10mm, for use through standard MIS trocar ports. Read the paper on the TAROS proceedings <a href='https://taros-conference.org/wp-content/uploads/2024/08/TAROS_2024-15051_rev.pdf'>here</a>, p282.</p>
              <p>PALPABLE expert Prof. Althoefer also featured as a keynote lecturer at the event, speaking on Physical Robot-Environment Interaction: The Continued Quest for Tactile-Grounded Manipulation, and led a panel on advances and challenges in robotics and AI. Watch the keynote <a href='https://www.youtube.com/watch?v=rvTE9XUc-vU'>here</a> and the panel discussion <a href='https://www.youtube.com/watch?v=sfoW9jERJQc'>here</a>.</p>

              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src={img1}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <img
                  src={img2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
  

      <Footer space />
    </Layout>
  );
}
